// extracted by mini-css-extract-plugin
export var bgGrey100 = "DeliveryDate-module--bg-grey-100--d9eae";
export var bgGrey150 = "DeliveryDate-module--bg-grey-150--684c7";
export var bgGrey200 = "DeliveryDate-module--bg-grey-200--4697e";
export var bgGrey300 = "DeliveryDate-module--bg-grey-300--79829";
export var bgGrey400 = "DeliveryDate-module--bg-grey-400--e3274";
export var bgGrey500 = "DeliveryDate-module--bg-grey-500--ea90b";
export var bgGrey600 = "DeliveryDate-module--bg-grey-600--29205";
export var bgGrey700 = "DeliveryDate-module--bg-grey-700--301fd";
export var bgGrey800 = "DeliveryDate-module--bg-grey-800--9118d";
export var bgGrey900 = "DeliveryDate-module--bg-grey-900--e5f3e";
export var section = "DeliveryDate-module--section--3e366";
export var textGrey100 = "DeliveryDate-module--text-grey-100--983c9";
export var textGrey150 = "DeliveryDate-module--text-grey-150--84384";
export var textGrey200 = "DeliveryDate-module--text-grey-200--26438";
export var textGrey300 = "DeliveryDate-module--text-grey-300--1f322";
export var textGrey400 = "DeliveryDate-module--text-grey-400--8c1ea";
export var textGrey500 = "DeliveryDate-module--text-grey-500--88728";
export var textGrey600 = "DeliveryDate-module--text-grey-600--287b9";
export var textGrey700 = "DeliveryDate-module--text-grey-700--76849";
export var textGrey800 = "DeliveryDate-module--text-grey-800--dd4f2";
export var textGrey900 = "DeliveryDate-module--text-grey-900--12887";