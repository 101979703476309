// extracted by mini-css-extract-plugin
export var addressCheckbox = "basket-delivery-options-module--addressCheckbox--d46a7";
export var bgGrey100 = "basket-delivery-options-module--bg-grey-100--bfbfe";
export var bgGrey150 = "basket-delivery-options-module--bg-grey-150--2552b";
export var bgGrey200 = "basket-delivery-options-module--bg-grey-200--f8935";
export var bgGrey300 = "basket-delivery-options-module--bg-grey-300--fbe1f";
export var bgGrey400 = "basket-delivery-options-module--bg-grey-400--db1a3";
export var bgGrey500 = "basket-delivery-options-module--bg-grey-500--4d8b9";
export var bgGrey600 = "basket-delivery-options-module--bg-grey-600--95637";
export var bgGrey700 = "basket-delivery-options-module--bg-grey-700--31edf";
export var bgGrey800 = "basket-delivery-options-module--bg-grey-800--67fc8";
export var bgGrey900 = "basket-delivery-options-module--bg-grey-900--a7e7e";
export var bgOverlay = "basket-delivery-options-module--bgOverlay--d9488";
export var bikeImg = "basket-delivery-options-module--bikeImg--9d4aa";
export var blackBorder = "basket-delivery-options-module--blackBorder--72df1";
export var blackColor = "basket-delivery-options-module--blackColor--e48ed";
export var builtDiv = "basket-delivery-options-module--builtDiv--f8db4";
export var countProducts = "basket-delivery-options-module--countProducts--224d3";
export var deliveryCost = "basket-delivery-options-module--deliveryCost--e1b40";
export var deliveryCostInfoIcon = "basket-delivery-options-module--deliveryCostInfoIcon--de124";
export var deliveryOptionContainer = "basket-delivery-options-module--deliveryOptionContainer--30855";
export var deliveryOptionText = "basket-delivery-options-module--deliveryOptionText--1f1f8";
export var deliveryOptions = "basket-delivery-options-module--deliveryOptions--e5d42";
export var deliveryOptionsMob = "basket-delivery-options-module--deliveryOptionsMob--a7f94";
export var grayLine = "basket-delivery-options-module--grayLine--edce8";
export var grayText = "basket-delivery-options-module--grayText--c01a3";
export var greyBorder = "basket-delivery-options-module--greyBorder--99463";
export var greyColor = "basket-delivery-options-module--greyColor--53dcb";
export var helpText = "basket-delivery-options-module--helpText--a4a41";
export var inputContainer = "basket-delivery-options-module--inputContainer--95838";
export var inputSideBySide = "basket-delivery-options-module--inputSideBySide--4d05d";
export var marketingBg = "basket-delivery-options-module--marketingBg--3601d";
export var mobilePriceSticker = "basket-delivery-options-module--mobilePriceSticker--8c531";
export var needHelp = "basket-delivery-options-module--needHelp--48985";
export var orderSummary = "basket-delivery-options-module--orderSummary--752eb";
export var payBtn = "basket-delivery-options-module--payBtn--1d7da";
export var priceSticker = "basket-delivery-options-module--priceSticker--0fe03";
export var redBox = "basket-delivery-options-module--redBox--51c53";
export var rideDiv = "basket-delivery-options-module--rideDiv--c117a";
export var section = "basket-delivery-options-module--section--897fd";
export var selectedDeliveryOptionText = "basket-delivery-options-module--selectedDeliveryOptionText--e5f8a";
export var servicesText = "basket-delivery-options-module--servicesText--feddc";
export var textGrey100 = "basket-delivery-options-module--text-grey-100--62c5c";
export var textGrey150 = "basket-delivery-options-module--text-grey-150--54414";
export var textGrey200 = "basket-delivery-options-module--text-grey-200--f2d93";
export var textGrey300 = "basket-delivery-options-module--text-grey-300--37a99";
export var textGrey400 = "basket-delivery-options-module--text-grey-400--2adc0";
export var textGrey500 = "basket-delivery-options-module--text-grey-500--e880f";
export var textGrey600 = "basket-delivery-options-module--text-grey-600--ce93b";
export var textGrey700 = "basket-delivery-options-module--text-grey-700--9e565";
export var textGrey800 = "basket-delivery-options-module--text-grey-800--ccda4";
export var textGrey900 = "basket-delivery-options-module--text-grey-900--340c8";
export var timeSection = "basket-delivery-options-module--timeSection--86d68";