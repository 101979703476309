import { StaticImage } from "gatsby-plugin-image"
import React, { useState, useEffect, Fragment } from "react"
import {
  Row,
  Container,
  Card,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/Layout"
import ClickAndCollect from "../components/BasketUI/ClickAndCollect"
import OrderSummary from "../components/BasketUI/OrderSummary"
import DeliveryDate from "../components/UI/DeliveryDate/DeliveryDate"
import HelpBlock from "../components/UI/HelpBlock/HelpBlock"
import Checkmark from "../images/icon-cart-icon-check.inline.svg"
import { basketSelectors, basketActions } from "../state/basket"
import { createDisplayPrice } from "../utils/productsHelper"
import { dataLayerUpdate } from "../api/dataLayer/gaDataLayer"

import HomeDelivery from "./homeDelivery"
import * as style from "./basket-delivery-options.module.scss"

const clearStyle = {
  clear: "both",
}

const BasketDeliveryOptions = ({
  basketItems,
  basketTotal,
  basketTotalWithDiscount,
  basketItemsCount,
  couponCodeFromStore,
  addShippingFee,
  removeShippingFee,
  isShippingSelected,
  calculatedShippingFee,
  removeCouponCode,
  extraFees,
}) => {
  const [deliveryOption, setDeliveryOption] = useState("delivery")
  useEffect(() => {
    addShippingFee()
    if (basketItems && basketTotal && basketTotalWithDiscount) {
      dataLayerUpdate("add_shipping_info", {
        basketItems: basketItems,
        basketTotal: basketTotal,
        basketTotalWithDiscount: basketTotalWithDiscount,
      })
    }
  }, [])
  const handleChange = e => {
    const { id } = e.target
    if (id !== deliveryOption) setDeliveryOption(id)
    id !== "clickAndCollect" ? addShippingFee() : removeShippingFee()
  }

  const renderOrderSummary = () => {
    return (
      <div className="d-none d-lg-block">
        <OrderSummary
          orderStage="basket-delivery-options"
          extraFees={extraFees}
          basketItemsCount={basketItemsCount}
          basketItems={basketItems}
          basketTotal={basketTotal}
          shippingFee={calculatedShippingFee}
          isShippingSelected={isShippingSelected}
          couponCodeFromStore={couponCodeFromStore}
          basketTotalWithDiscount={basketTotalWithDiscount}
          removeCouponCode={removeCouponCode}
        />
      </div>
    )
  }

  const OptionCost = ({ isHomeDelivery }) => {
    const formatedDeliveryCost = createDisplayPrice(
      calculatedShippingFee,
      "basket-delivery-options option selector"
    )
    const deliveryCostInfo = `Free on orders over ${createDisplayPrice(
      process.env.FREE_SHIPPING_MINIMUM,
      "basket-delivery-options option selector"
    )}`

    const renderedDeliveryCharge = formatedDeliveryCost
      ? formatedDeliveryCost
      : `${
          basketTotal < Number(process.env.FREE_SHIPPING_MINIMUM)
            ? "Free / £"
            : "Free"
        }`

    return (
      <span
        className={`h4 mt-2 text-uppercase position-absolute ${
          isHomeDelivery
            ? styled.selectedDeliveryOptionText
            : style.deliveryOptionText
        }`}>
        {calculatedShippingFee ? (
          <OverlayTrigger
            overlay={
              <Tooltip id="delivery-cost-info">{deliveryCostInfo}</Tooltip>
            }>
            <div className={style.deliveryCost}>
              {renderedDeliveryCharge}
              <span className={style.deliveryCostInfoIcon}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            </div>
          </OverlayTrigger>
        ) : (
          <>{renderedDeliveryCharge}</>
        )}
      </span>
    )
  }

  const renderDeliveryOptions = () => {
    const isHomeDelivery = deliveryOption === "delivery"
    const isClickAndCollect = deliveryOption === "clickAndCollect"

    return (
      <Row>
        {/* delivery options */}
        <Col
          className={`px-0  m-2 ${
            style.deliveryOptionContainer
          } ${isHomeDelivery && "border-dark"}`}>
          <div
            className={`position-relative d-flex form-group align-content-center form-check p-3`}>
            <div className="pl-4 pr-2 d-flex align-items-center">
              <input
                type="checkbox"
                className={` form-check-input`}
                id="delivery"
                required
                checked={deliveryOption === "delivery"}
                onClick={handleChange}
              />
            </div>
            <div className="d-flex align-content-center pt-2 pt-lg-0">
              <StaticImage
                src="../../src/images/icon-delivery.png"
                alt="Delivery van"
                width={64}
                className="mr-3"
                placeholder="none"
                objectFit="scale-down"
              />
              <label
                className={`d-flex align-items-center h3 mb-0 text-uppercase ${
                  isHomeDelivery
                    ? styled.selectedDeliveryOptionText
                    : style.deliveryOptionText
                }`}>
                delivery
              </label>
            </div>

            <OptionCost isHomeDelivery={isHomeDelivery} />
          </div>
        </Col>

        <Col
          className={`px-0  m-2 ${
            style.deliveryOptionContainer
          } ${isClickAndCollect && "border-dark"} `}>
          <div
            className={`position-relative d-flex form-group align-content-center form-check p-3`}>
            <div className="pl-4 pr-2 d-flex align-items-center">
              <input
                type="checkbox"
                className={` form-check-input`}
                id="clickAndCollect"
                required
                checked={deliveryOption === "clickAndCollect"}
                onClick={handleChange}
              />
            </div>
            <div className="d-flex align-content-center pt-2 pt-lg-0">
              <StaticImage
                src="../../src/images/icon-clickandcollect.png"
                alt="Delivery van"
                width={64}
                className="mr-3"
                placeholder="none"
                objectFit="scale-down"
              />
              <label
                className={`d-flex align-items-center h3 mb-0 text-uppercase ${
                  isClickAndCollect
                    ? styled.selectedDeliveryOptionText
                    : style.deliveryOptionText
                }`}>
                click & collect
              </label>
            </div>
            <span
              className={`h4 mt-2 text-uppercase position-absolute ${
                isClickAndCollect
                  ? styled.selectedDeliveryOptionText
                  : style.deliveryOptionText
              }`}>
              free
            </span>
          </div>
        </Col>
      </Row>
    )
  }

  return (
    <Fragment>
      <Layout
        seoComponent={{
          seoTitle: "Basket - delivery options",
          seoDescription: "ready for checkout",
        }}
        location={{ pathname: "basket-delivery-options" }}>
        <section className={`py-5 ${style.section}`}>
          <Container fluid="lg">
            <Row>
              <Col lg={8} className="px-lg-3">
                <div>
                  <Card className={`body p-3 p-lg-5`}>
                    <h1 className="pb-3 text-uppercase h2 text-left">
                      1 - delivery options
                      <br />
                      <br />
                    </h1>
                    <div className=" media d-none d-lg-block">
                      <div className="media-body d-flex my-3">
                        <h3 className="text-uppercase mr-2 pt-2">
                          choose your delivery method
                        </h3>
                        <span className="mt-1">
                          <Checkmark />
                        </span>
                      </div>
                    </div>
                    {renderDeliveryOptions()}
                    <>
                      <DeliveryDate deliveryOption={deliveryOption} />
                      <HomeDelivery
                        financeCheckout={false}
                        isSelected={deliveryOption === "delivery"}
                      />
                    </>
                    <ClickAndCollect
                      financeCheckout={false}
                      isSelected={deliveryOption === "clickAndCollect"}
                    />
                    <hr />
                    <p className="h2 text-uppercase gray-600 font-500 pl-5 py-4">
                      2 - payment
                    </p>
                  </Card>
                </div>
              </Col>
              {/* order summary  */}
              <Col lg={4} className="p-0">
                {renderOrderSummary()}
                <HelpBlock />
              </Col>
              {/* End of order summary */}
            </Row>
          </Container>
        </section>
        <div style={clearStyle} />
      </Layout>
    </Fragment>
  )
}

const mapStateToProps = createStructuredSelector({
  basketItems: basketSelectors.getItems,
  basketItemsCount: basketSelectors.getTotalItems,
  basketTotal: basketSelectors.getBasketTotal,
  couponCodeFromStore: basketSelectors.getCouponCode,
  basketTotalWithDiscount: basketSelectors.getBasketTotalWithDiscount,
  isShippingSelected: basketSelectors.isShippingSelected,
  calculatedShippingFee: basketSelectors.calculateShippingFee,
  extraFees: basketSelectors.getExtraFee,
})

const mapDispatchToProps = {
  addShippingFee: basketActions.addShippingFee,
  removeShippingFee: basketActions.removeShippingFee,
  removeCouponCode: basketActions.removeCouponCode,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasketDeliveryOptions)
