// extracted by mini-css-extract-plugin
export var bgGrey100 = "ClickAndCollect-module--bg-grey-100--ff72b";
export var bgGrey150 = "ClickAndCollect-module--bg-grey-150--47221";
export var bgGrey200 = "ClickAndCollect-module--bg-grey-200--b990e";
export var bgGrey300 = "ClickAndCollect-module--bg-grey-300--14b2c";
export var bgGrey400 = "ClickAndCollect-module--bg-grey-400--914a4";
export var bgGrey500 = "ClickAndCollect-module--bg-grey-500--75f01";
export var bgGrey600 = "ClickAndCollect-module--bg-grey-600--fbc7b";
export var bgGrey700 = "ClickAndCollect-module--bg-grey-700--9b43d";
export var bgGrey800 = "ClickAndCollect-module--bg-grey-800--334e8";
export var bgGrey900 = "ClickAndCollect-module--bg-grey-900--e0c6d";
export var branchContainer = "ClickAndCollect-module--branchContainer--8b2d2";
export var details = "ClickAndCollect-module--details--13562";
export var inputSideBySide = "ClickAndCollect-module--inputSideBySide--d518a";
export var payBtn = "ClickAndCollect-module--payBtn--5b774";
export var section = "ClickAndCollect-module--section--14ec3";
export var selectedBranch = "ClickAndCollect-module--selectedBranch--71690";
export var textGrey100 = "ClickAndCollect-module--text-grey-100--7d45f";
export var textGrey150 = "ClickAndCollect-module--text-grey-150--7e1d6";
export var textGrey200 = "ClickAndCollect-module--text-grey-200--f971c";
export var textGrey300 = "ClickAndCollect-module--text-grey-300--d4412";
export var textGrey400 = "ClickAndCollect-module--text-grey-400--1c61f";
export var textGrey500 = "ClickAndCollect-module--text-grey-500--0bb62";
export var textGrey600 = "ClickAndCollect-module--text-grey-600--34620";
export var textGrey700 = "ClickAndCollect-module--text-grey-700--eeafe";
export var textGrey800 = "ClickAndCollect-module--text-grey-800--bc6ce";
export var textGrey900 = "ClickAndCollect-module--text-grey-900--f6b70";