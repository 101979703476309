import * as style from "./DeliveryDate.module.scss"
import React from "react"

const DeliveryDate = ({ deliveryOption }) => {
  /**
   * Renders delivery date panel
   *
   * @param {String?Number?Object} renderDeliveryPanel - date of delivery,pick up location.
   *
   */

  const today = new Date()
  const options = { weekday: "short", month: "short", day: "numeric" }

  let deliveryEstimateStart = new Date(
    today.setDate(today.getDate() + 5)
  )

  let deliveryEstimateEnd = new Date(
    today.setDate(deliveryEstimateStart.getDate() + 2)
  )

  return deliveryOption === "delivery" ? (
    <div className={style.section}>
      <p className="p-4 mb-0 text-center ">
        <span className="h4 mb-0 text-uppercase">estimated arrival date</span>
        <br></br>
        {`Between ${deliveryEstimateStart.toLocaleDateString("en-GB", options)} – ${deliveryEstimateEnd.toLocaleDateString("en-GB", options)}`}
      </p>
    </div>
  ) : (
    <div className={style.section}>
      <p className="p-4 mb-0 text-center">
        Collected from store between 9am-5:30pm following all up to date
        Covid-19 Government guidelines.
      </p>
    </div>
  )
}

export default DeliveryDate