import React, { useState, Fragment } from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { basketActions } from "../../state/basket"
import Col from "react-bootstrap/Col"
import { Row } from "react-bootstrap"
import { Button, Card, Form } from "react-bootstrap"
import Checkmark from "../../images/icon-cart-icon-check.inline.svg"
import StoreLocatorModal from '../../templates/ProductUI/StoreLocatorModal';
import { ModalPortal, ToggleModal } from "../../portals/Modal.portal";
import {navigate} from "gatsby";
import {BRANCH_DETAILS} from '../../utils/storeLocationHelper'
import * as style from "./ClickAndCollect.module.scss"

const clearStyle = {
  clear: "both",
}

const ClickAndCollect = ({ isSelected, saveFormDetails, financeCheckout }) => {
  const [formValidated, setFormValidated] = useState(false);
  const [processing, setProcessing] = useState(false);
  const defaultStore = process.env.DEFAULT_CLICK_AND_COLLECT_STORE

  const buttonId = financeCheckout === true ? "financeClickAndCollectContinue" : "checkoutClickAndCollectContinue"

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: ""
  })


  const [pickupLocation, setPickupLocation] = useState(defaultStore)

  const { firstName, lastName, email, phoneNumber } = formData

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })


  const handleChange = e => {
    const { name } = e.target
    if (name !== pickupLocation) setPickupLocation(name)
  }

  const handleSubmit = async ev => {
    setProcessing(true);
    ev.preventDefault();

    // form validation
    const form = ev.currentTarget;

    if (form.checkValidity() === false) {
      ev.stopPropagation();
      setFormValidated(true);
      setProcessing(false);
    } else {
      setFormValidated(false);
      const payload = {...formData,pickupLocation, isActive: isSelected, deliveryMethod: 'clickAndCollect', shippingMethod: "FREE"}
      saveFormDetails(payload)
      financeCheckout === true ? navigate('/finance-checkout-summary') : navigate('/checkout')
    }
  }



  return isSelected ? (
    <Fragment>
      <Form
        id="contactForm"
        action={`${process.env.DELIVERY_WORDPRESS_FORM_URL}`}
        noValidate
        validated={formValidated}
        onSubmit={handleSubmit}
        method="post">
      <div>
        <div className=" media">
          <div className="media-body d-flex my-3">
            <h3 className="text-uppercase mr-2 pt-2">
              choose the store you want to pick-up from
            </h3>
            <span className="d-lg-block mt-1">
              <Checkmark />
            </span>
          </div>
        </div>
        <Row className="justify-content-center flex-column">
          {React.Children.toArray(BRANCH_DETAILS.map(branch => {
            let isActive = pickupLocation === branch.branchName
            return (
            <Col className={`mb-4 ${style.branchContainer}`}>
              <Card body
                className={`rounded ${style.branchContainer} ${isActive ? 'border-dark':''} `}
                >
                <input
                  type="checkbox"
                  name={branch.branchName}
                  checked={isActive}
                  onClick={e => handleChange(e)}
                />
                <label className="pl-5 mb-0">
                  <p className={`mb-1 text-uppercase ${isActive ? style.selectedBranch: 'text-greyed' }`}>{branch.branchName}</p>
                  <p className={`mb-0 ${isActive ? style.selectedBranch : 'text-greyed'}`}>{`${branch.street} ${branch.city}`}</p>
                </label>
                <ToggleModal
                      toggle={
                        show => <a className={`mt-2 p-3 ml-sm-3 ${style.details}`} onClick={show}> Details </a>
                      }
                      content={
                        hide => {
                          return (
                            <ModalPortal hide={hide} size="xl" centered={true}>
                              <StoreLocatorModal branch={branch}/>
                            </ModalPortal>
                          )
                        }
                      }
                    />
              </Card>
            </Col>)
          }
          ))}
        </Row>

        <p className="text-uppercase mt-4">your information</p>
        <Row>
          <Col xs={10} lg={6}>
            <Form.Group className={style.inputSideBySide} controlId="firstName">
              <Form.Label>First Name*</Form.Label>
              <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="Emily"
                  className="rounded-0 p-3"
                  onChange={e => onChange(e)}
                  value={firstName}
                  required
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="E.g Robins"
                  className="rounded-0 p-3"
                  onChange={e => onChange(e)}
                  value={lastName}
                  required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="email">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="E.g arianne.whittaker@gmail.com"
                className={`rounded-0 p-4`}
                onChange={e => onChange(e)}
                value={email}
                required
              />
            </Form.Group>
            </Col>
            { financeCheckout && (
            <Col>
            <Form.Group controlId="phoneNumber">
              <Form.Label>
                Phone Number <span className="gray-600">*</span>
              </Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                placeholder="E.g 020 4134 5678"
                className="rounded-0 p-3"
                onChange={e => onChange(e)}
                required
                value={phoneNumber}
              />
            </Form.Group>
          </Col>
          )}

        </Row>
        <Button
          className={`px-4 mt-3 mr-xl-0 btn-block ${style.payBtn}`}
          variant="primary"
          id={buttonId}
          type="submit"
          >
          { processing ?
              'Processing...'
              :
              <span className="ml-2 align-middle">
                {financeCheckout === true ? 'Next Step' : 'Continue To Payment'}
              </span>
          }
        </Button>
        </div>
        </Form>
      <div style={clearStyle} />
    </Fragment>
  ) : null
}

const mapStateToProps = createStructuredSelector({})

const mapDispatchToProps = {
  saveFormDetails: basketActions.saveFormDetails,

}
export default connect(mapStateToProps, mapDispatchToProps)(ClickAndCollect)
