import React, { Fragment } from 'react';
import { Row,Col } from 'react-bootstrap';

import * as style from './StoreLocatorModal.module.scss';

const StoreLocatorModal = ({ branch: { brancheName, street, addressLine2, city, postcode, phone, openingHours  }}) => {
    return (
        <Fragment>
          <Row className="m-0 p-0 flex-nowrap flex-lg-row flex-column-reverse" >
            <Col sm={12} lg={8} xl={8} className="p-0">
            <div>
            <iframe
               title={brancheName}
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.6232094867373!2d-0.2241368842302472!3d51.501781579634255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760fc59e03647d%3A0x6cd40c0276234264!2sCentral Bikes!5e0!3m2!1sen!2suk!4v1669209881733!5m2!1sen!2suk" width="100%" height="550" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            </Col>
            <Col sm={12} lg={4} xl={4} className="py-5 pl-3">
              <address className={`d-flex flex-column justify-content-center border-bottom ${style.storeDetails}`}>
                <h2 className="text-uppercase mb-0 pb-3">{brancheName}</h2>
                {street}<br />
                {addressLine2 && <> {addressLine2}<br /></> }
                {`${city}, ${postcode}`}<br />
                <span className="pt-3">{phone}</span>
              </address>
              <div className={style.openingHours}>
                <h3 className="pb-4 text-uppercase mb-0">Opening Hours</h3>
                <div>
                  { openingHours.map((day,i) =>
                  <h4 className={`d-flex justify-content-between text-uppercase mb-0 ${style.openingDay}`} key={i}>
                    {day['dayOfTheWeek']} {' '}
                    <span>{day['hours']}</span>
                  </h4>
                  )}
                </div>
              </div>
              </Col>
          </Row>
        </Fragment>
    );
}


export default StoreLocatorModal