// extracted by mini-css-extract-plugin
export var addressDetail = "StoreLocatorModal-module--addressDetail--aade7";
export var bgGrey100 = "StoreLocatorModal-module--bg-grey-100--facdf";
export var bgGrey150 = "StoreLocatorModal-module--bg-grey-150--58bfb";
export var bgGrey200 = "StoreLocatorModal-module--bg-grey-200--45a23";
export var bgGrey300 = "StoreLocatorModal-module--bg-grey-300--8fc75";
export var bgGrey400 = "StoreLocatorModal-module--bg-grey-400--4f0ec";
export var bgGrey500 = "StoreLocatorModal-module--bg-grey-500--9e1cc";
export var bgGrey600 = "StoreLocatorModal-module--bg-grey-600--8234b";
export var bgGrey700 = "StoreLocatorModal-module--bg-grey-700--bfff3";
export var bgGrey800 = "StoreLocatorModal-module--bg-grey-800--bc6ce";
export var bgGrey900 = "StoreLocatorModal-module--bg-grey-900--531cd";
export var openingDay = "StoreLocatorModal-module--openingDay--5bb25";
export var openingHours = "StoreLocatorModal-module--openingHours--22055";
export var storeDetails = "StoreLocatorModal-module--storeDetails--c799a";
export var textGrey100 = "StoreLocatorModal-module--text-grey-100--42e2c";
export var textGrey150 = "StoreLocatorModal-module--text-grey-150--1fb65";
export var textGrey200 = "StoreLocatorModal-module--text-grey-200--75865";
export var textGrey300 = "StoreLocatorModal-module--text-grey-300--6f114";
export var textGrey400 = "StoreLocatorModal-module--text-grey-400--44345";
export var textGrey500 = "StoreLocatorModal-module--text-grey-500--98148";
export var textGrey600 = "StoreLocatorModal-module--text-grey-600--29053";
export var textGrey700 = "StoreLocatorModal-module--text-grey-700--15122";
export var textGrey800 = "StoreLocatorModal-module--text-grey-800--48eeb";
export var textGrey900 = "StoreLocatorModal-module--text-grey-900--dd8c8";